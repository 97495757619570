<template>
  <div
    v-if="list"
    class="w-full grid justify-center gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-[repeat(auto-fit,_25%)] xl:grid-cols-[repeat(auto-fit,_20%)] 2xl:grid-cols-[repeat(auto-fit,_17%)]"
  >
    <a
      v-for="item in list"
      :key="item.url"
      :href="item.url"
      class="group hover:bg-primary-8 bg-base-2 rounded shadow-md border border-neutral-7 hover:border-primary-1 hover:text-primary-0 contrast:bg-c-secondary-0 contrast:border-base-1 contrast:hover:bg-c-primary-0 contrast:hover:text-base-2 contrast:hover:border-c-secondary-0"
    >
      <div class="flex gap-4 sm:gap-0 sm:flex-col items-center sm:p-4 p-2">
        <div
          class="flex justify-start lg:justify-center w-24 h-24 sm:w-48 sm:h-48 shrink-0"
        >
          <LoadingImage
            class="rounded contrast:text-base-1 contrast:group-hover:text-base-2"
            :src="item.iconPath"
            :alt="`${item.label}_no_image`"
          />
        </div>
        <div
          class="flex items-center justify-center sm:pt-4 lg:text-center font-medium text-base"
        >
          {{ item.label }}
        </div>
      </div>
    </a>
  </div>
</template>
<script setup lang="ts">
const { data } = await useApi("/api/moduleList");
const config = useRuntimeConfig();
const requestUrl = ref<string>();

onMounted(() => {
  requestUrl.value = `${window.location.protocol}//${window.location.host}`;
});

const list = computed(() => {
  const modules: {
    label: string;
    url: string;
    iconPath: string;
  }[] = [];
  data.value?.modules.forEach((module) => {
    let url;
    if (config.public.recrutationUrl) {
      url = `${config.public.recrutationUrl}${module.url}`;
    } else {
      url = `${requestUrl.value}${module.url}`;
    }
    modules.push({
      label: module.label,
      url: url,
      iconPath: `${module.name}.jpg`,
    });
  });

  return modules;
});
</script>
